<template>
  <div class="epc-index">
    <h3>請掃描或輸入衣物條碼</h3>
    <input type="text" ref="epcInput" v-model="inputEpc" />
    <button class="btn btn-sm btn-outline-info" @click="readTemplate">確認</button>

    <EpcClothTemplateModal v-if="isTemplateModalShow" :cloth="clothTemplate" :epc="inputEpc" @back="isTemplateModalShow = false"></EpcClothTemplateModal>
  </div>
</template>

<script>
import EpcClothTemplateModal from './EpcClothTemplateModal.vue';

export default {
  name: 'EpcIndex',
  data() {
    return {
      inputEpc: '',
      clothTemplate: null,

      isTemplateModalShow: false,
    };
  },
  components: {
    EpcClothTemplateModal,
  },
  props: {
  },
  created() {
  },
  beforeDestroy() {
  },
  mounted() {
    this.$refs.epcInput.focus();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async readTemplate() {
      try {
        const template = await this.$store.dispatch('api/readTemplateByEpcPromise', this.inputEpc);
        if (template === null) {
          alert('找不到條碼!');
        } else {
          this.clothTemplate = Object.assign({}, this.clothTemplate, template);
          this.isTemplateModalShow = true;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style scoped>
  .epc-index {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .epc-index>input[type="text"] {
    border: none;
    border-bottom: solid 1px #000;
    text-align: center;
    width: 50%;
    margin-bottom: 1rem;
  }

  .epc-index>input[type="text"]:focus {
    outline: none;
  }
</style>
